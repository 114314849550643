





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Linkedin extends Vue {
  @Prop() fill!: string;
}
