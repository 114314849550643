








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Email extends Vue {
  @Prop() fill!: string;
}
