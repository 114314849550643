
































































import { Component, Prop, Vue } from 'vue-property-decorator';

import Facebook from '@/assets/icons/social/Facebook.vue';
import Email from '@/assets/icons/social/Email.vue';
import Whatsapp from '@/assets/icons/social/Whatsapp.vue';
import Instagram from '@/assets/icons/social/Instagram.vue';
import Linkedin from '@/assets/icons/social/Linkedin.vue';
import Telegram from '@/assets/icons/social/Telegram.vue';
import Twitter from '@/assets/icons/social/Twitter.vue';

@Component({
  components: {
    Facebook,
    Email,
    Whatsapp,
    Instagram,
    Linkedin,
    Telegram,
    Twitter,
  },
})
export default class ListButtonShare extends Vue {
  @Prop() url!: string;

  copyText(): void {
    const input = this.$refs.inputURL as HTMLInputElement;
    input.select();
    document.execCommand('copy');

    this.$store.dispatch('Toast/setToast', {
      status: 'info',
      text: 'Link copiado para a área de transferência!',
    });
  }

  encodeURL(): string {
    return encodeURIComponent(this.url);
  }
}
